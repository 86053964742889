import * as React from "react";
import Helmet from "react-helmet";

import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import coreClients from "../images/corevalue-clients.svg";
import coreSide from "../images/corevalue-side.svg";
import coreExcellence from "../images/corevalue-excellence.svg";
import coreFulfilment from "../images/corevalue-fulfilment.svg";
import coreRightThing from "../images/corevalue-rightthing.svg";
import coreTrue from "../images/corevalue-true.svg";

import { PageWrapper, TextWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import NextButton from "../components/next-button";
import { Fade } from "react-reveal";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;
  }
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 25%;
  max-width: 175px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
  color: #020f73;
  font-family: sans-serif;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;

  ${respondTo.xsmall`
    flex-direction: row; 
  `}

  & div {
    flex: 1;
  }
`;

const ValueWrapper = styled.div`
  padding: 1em;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & p {
    font-size: 150%;
  }
`;

// markup
const OurPurposePage = () => {
  return (
    <>
      <Helmet>
        {" "}
        <title>Our Purpose | True Wealth Group</title>
        <meta name="description" content="True Wealth Group's purpose" />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <PageWrapper>
        <Navigation></Navigation>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
          loading="eager"
        />
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%", aspectRatio: "1/1" }}
            alt="True Wealth Group logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <h1>Our Purpose</h1>
        </ContentWrapper>
        <TextWrapper>
          <p>
            We are building one of the UK’s leading financial planning wealth
            management groups – but doing so with a difference. Below we set out
            our Purpose, Vision and Mission statements and our Core Values.
            These were arrived at collaboratively with input from the whole team
            as part of our Fulfilment Project. See if they resonate with you and
            get in touch if they do.
          </p>
        </TextWrapper>
        <TextWrapper>
          <h2>Our Purpose Statement (the ‘why’)</h2>
          <p>
            Being wealthy means different things to different people. We are
            passionate about helping people achieve their true wealth and the
            positive impact this has on our clients, colleagues and communities.{" "}
          </p>
        </TextWrapper>
        <TextWrapper>
          <h2>Our Vision Statement (the ‘what’)</h2>
          <p>
            Our vision is to combine the resources and expertise of a national
            business with the personal service and focus of a boutique firm. We
            will use our scale and compassion to create impact and influence as
            a force for good.
          </p>
        </TextWrapper>
        <TextWrapper>
          <h2>Our Mission Statement (the ‘how’)</h2>
          <p>
            We make a difference every day. We invest time to uncover and
            understand a clients’ goals, ambitions and values. We harness
            technology, skill and creativity to deliver financial freedom and
            peace of mind, doing for clients what they would do for themselves
            if they had the time, inclination and expertise.
          </p>
        </TextWrapper>
        <TextWrapper id="corevalues">
          <h2>Our Core Values</h2>
          <p>
            We hold our selves and our business to account by embracing a set of
            guiding values. Developed in conjunction with our team they are;
          </p>
          <FlexWrapper>
            <ValueWrapper>
              <Fade>
                <img
                  src={coreClients}
                  style={{ width: "200px", paddingTop: "20%" }}
                  alt={"Clients at the heart of our business"}
                />

                <p>Clients at the heart of our business</p>
              </Fade>
            </ValueWrapper>{" "}
            <ValueWrapper>
              {" "}
              <Fade>
                <img
                  src={coreRightThing}
                  style={{ width: "175px", paddingTop: "10%" }}
                  alt={"Do the right thing"}
                />
                <p>Do the right thing</p>{" "}
              </Fade>
            </ValueWrapper>{" "}
            <ValueWrapper>
              {" "}
              <Fade>
                <img
                  src={coreExcellence}
                  style={{ width: "200px", paddingTop: "10%" }}
                  alt={"Strive for excellence"}
                />
                <p>Strive for excellence</p>{" "}
              </Fade>
            </ValueWrapper>
          </FlexWrapper>
          <FlexWrapper>
            {" "}
            <ValueWrapper>
              {" "}
              <Fade>
                <img
                  src={coreSide}
                  style={{ width: "200px" }}
                  alt={"Side before self"}
                />

                <p>Side before self</p>
              </Fade>
            </ValueWrapper>
            <ValueWrapper>
              {" "}
              <Fade>
                <img
                  src={coreFulfilment}
                  style={{ width: "246px" }}
                  alt={"Fulfilment for our people"}
                />
                <p>Fulfilment for our people</p>{" "}
              </Fade>
            </ValueWrapper>
          </FlexWrapper>

          <ValueWrapper>
            <Fade>
              <p>
                Think big, have fun, do good, be
                <img
                  src={coreTrue}
                  style={{ width: "100px", marginLeft: "0.5em" }}
                  alt={"Think big, have fun, do good, be true"}
                />
              </p>
            </Fade>
          </ValueWrapper>
        </TextWrapper>
        <NextButton url="/growth-strategy" name="Growth Strategy" />
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default OurPurposePage;

export const Head = () => (
  <>
    <title>Our Purpose | True Wealth Group</title>
    <meta name="description" content="True Wealth Group's purpose" />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
